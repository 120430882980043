/*eslint-disable */
import toast from '@/services/toast';
import {bus} from '@/main';
export default {
  name: 'forgot-pw',
  components: {},
  props: [],
  data () {
    return {
      email:'',
      otp:'',
      submitted:false,
      forgotPwFirstStep:true,
      forgotPwSecondStep:false,
      confirm_password:'',
      password:''
    }
  },
  computed: {

  },
  mounted () {
    bus.$emit('showHeaderbtn', false);
    bus.$emit('showHeaderSection', false);
    if (this.$store.getters.isAuthenticated) {
      this.$router.push('/dashboard/')
    }
  },
  methods: {
    validateForgotPwBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            email: this.email,
          }
          this.$store.dispatch('authUserForgotPwApiFun', data).then((res) => {
            toast.success(res.data.msg);
            this.forgotPwFirstStep = false
            this.forgotPwSecondStep = true
            this.submitted =  false
          }).catch((err) => {
            toast.error(err.data.msg);
            this.submitted =  false
          })
         return;
        }
      })
    },
    validateForgotPwVerifyBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            email: this.email,
            otp:this.otp,
            password:this.password
          }
          this.$store.dispatch('authUserForgotPwVerifyApiFun', data).then((res) => {
            toast.success(res.data.msg);
            this.submitted =  false
            this.$router.push('/login/');
          }).catch((err) => {
            toast.error(err.data.msg);
            this.submitted =  false
          })
         return;
        }
      })
    }
  }
}


